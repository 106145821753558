import React, { useState, useContext } from "react";
import Axios from "axios";
import { AuthenticationContext } from "../../contexts/AuthenticationContext";
import { BeatLoader } from "react-spinners";

export default function GetDevice({ updateDevice, setNotice }) {
  const { person, dispatch } = useContext(AuthenticationContext);
  const [serialNumber, setSerialNumber] = useState("");
  let [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    Axios.get("/api/Devices/GetDeviceByImei", {
      params: { imei: serialNumber },
    })
      .then((response) => {
        updateDevice({
          set: true,
          id: response.data.id,
          process: false,
          data: response.data,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          dispatch({ type: "LOGOUT", action: "" });
        }
        setNotice({ status: false, message: error.message });
      });
  };

  return (
    <>
      <div className={loading === true ? "spinner" : "spinner-hide"}>
        <BeatLoader color="#8cd024" loading={loading} size="40px" />
      </div>

      <form className="form">
        <div className="row mb-1 p-2">
          <label htmlFor="serialNumber" className="form-label">
            IMEI Number
          </label>
          <input
            className="form-control"
            type="text"
            placeholder=""
            aria-label="form-control"
            name="serialNumber"
            value={serialNumber}
            onChange={(e) => setSerialNumber(e.target.value)}
          />
        </div>
        <div className="row p-2">
          <button
            type="Submit"
            className="btn-primary"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Search
          </button>
        </div>
      </form>
    </>
  );
}
