import React, { useContext } from "react";
import Logo from "../assets/images/tg-logo.png";
import { AuthenticationContext } from "../contexts/AuthenticationContext";
import Navbar from "../components/navbar/Index";
import MQueue from "./MQueue/Index";

export default function Header() {
  const { person } = useContext(AuthenticationContext);
  return (
    <div id="header" className="d-flex flex-row-reverse fixed-top">
      <img src={Logo} alt="logo" />
      <Navbar />
      <div className="userDetails">
        <p>
          Username: {person.firstName} {person.surname}
        </p>
        <p>Department: {person.department}</p>
      </div>
      <div className="messaeQueue">
        <MQueue />
      </div>
    </div>
  );
}
