import React, { useContext } from "react";
import "./index.scss";
import Nokia from "../../assets/images/Nokia.png";
import Hmd from "../../assets/images/hmd.png";
import { RejectionReasonsContext } from "../../contexts/RejectionReasonsContext";
import ReportType from "./ReportType";
//import { Document, Page, Text, View, StyleSheet } from "@react-18-pdf/renderer";
//import ReactPDF from "@react-18-pdf/renderer";

export default function Index(device) {
  const { rejectionReason } = useContext(RejectionReasonsContext);

  return (
    <div className="container page">
      <div className="row">
        <div className="col-3">
          <img src={Nokia} alt="problem loading..." className="logo" />
        </div>
        <div className="col"></div>
        <div className="col-3 logo-right">
          <img src={Hmd} alt="problem loading..." className="logo" />
        </div>
      </div>
      <div className="row ">
        <div className="col-12 title ">
          <h1>Assessment Rejection Report </h1>
        </div>
      </div>
      <div className="row">
        <div className="col heading">
          <h2>DOA/DAP Assessment Findings:</h2>
        </div>
      </div>
      <div className="row report-border">
        <div className="col-2 report-content report-content-heading border-right">
          Date
        </div>
        <div className="col-9 report-content ">{device.date}</div>
      </div>
      <div className="row report-border">
        <div className="col-2 report-content report-content-heading border-right">
          Customer Name
        </div>
        <div className="col-9 report-content">{device.customerName}</div>
      </div>
      <div className="row report-border">
        <div className="col-2 report-content report-content-heading border-right">
          Report Code:
        </div>
        <div className="col-9 report-content ">{device.id}</div>
      </div>
      <div className="row report-border ">
        <div className="col-2 report-content report-content-heading border-right">
          Make and Model:
        </div>
        <div className="col-9 report-content ">{device.model}</div>
      </div>
      <div className="row report-border">
        <div className="col-2 report-content report-content-heading border-right">
          IMEI Number:
        </div>
        <div className="col-9 report-content ">{device.model}</div>
      </div>
      <div className="row">
        <div className="col-11 report-paragraph">
          <p>
            This letter serves to notify that the above-mentioned device was
            assessed in accordance with the OEM guideline and based on the
            findings from the assessment the DOA/DAP is Rejected. The reason for
            rejection can be found below, and where applicable photographic
            evidence has also been provided.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col heading">
          <h2>Rejection Category:</h2>
        </div>
      </div>
      {rejectionReason.map((reason, index) => (
        <ReportType reason={reason} device={device} key={index} />
      ))}

      <div className="row">
        <div className="col heading">
          <h2>Photographic Evidence:</h2>
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-5 repot-image align-self-center">
          <img src="" alt="FrontImage" />
        </div>
        <div className="col-1"></div>
        <div className="col-5 repot-image">
          <img src="" alt="BackImage" />
        </div>
      </div>
      <div className="row ">
        <div className="col-12 mt-5">
          <div className="row d-flex justify-content-center">
            <div className="col-2">Technical Assessor:</div>
            <div className="col-2 report-border-bottom"></div>
            <div className="col-1">Signature:</div>
            <div className="col-2 report-border-bottom"></div>
            <div className="col-1">Date:</div>
            <div className="col-2 report-border-bottom"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
