import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthenticationContext } from "../../contexts/AuthenticationContext";

export default function Index() {
  const { dispatch } = useContext(AuthenticationContext);

  const Logout = () => {
    dispatch({ type: "LOGOUT", action: "" });
  };

  return (
    <nav className="navbar ">
      <form className="container-fluid justify-content-start">
        <Link to="/nokia">
          <button className="btn btn-outline-primary me-2" type="button">
            Nokia DOA/DOP
          </button>
        </Link>
        <Link to="/report">
          <button className="btn btn-outline-primary me-2" type="button">
            Report
          </button>
        </Link>

        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={Logout}
        >
          Logout
        </button>
      </form>
    </nav>
  );
}
