import React, { useContext, useState, useCallback, Redirect } from "react";
import { AuthenticationContext } from "../../contexts/AuthenticationContext";
import Axios from "axios";
import CaptureDevice from "./CaptureDevice";

export default function ProcessClaim(props) {
  const { dispatch } = useContext(AuthenticationContext);
  const { customerDevice, subCategory } = props;
  const [notice, SetNotice] = useState({
    status: false,
    message: null,
    error: false,
  });

  useCallback(() => {
    const data = {
      id: 0,
      customerName: customerDevice.customerName,
      productCode: customerDevice.productCode,
      model: customerDevice.model,
      materialDescription: customerDevice.materialDescription,
      salesModelName: customerDevice.salesModelName,
      soldToCustomer: customerDevice.soldToCustomer,
      countryCode: customerDevice.countryCode,
      serialPrimary: customerDevice.serialPrimary,
      faultReason: customerDevice.faultReason.reduce(
        (id, value) => `${value},`
      ),
      returnDate: customerDevice.returnDate,
      popDate: customerDevice.popDate,
      approved: customerDevice.approved,
      verifiedByTechnician: customerDevice.verifiedByTechnician,
      technicianComments: customerDevice.technicianComments,
      justificationApproval: customerDevice.justificationApproval,
      iD_RejectionSubCategory: subCategory,
      parts: JSON.stringify(Object.assign(customerDevice.parts)),
      originalImei: customerDevice.originalImei,
      comments: customerDevice.comments,
      serviceCenterNumber: customerDevice.serviceCenterNumber,
      accessories: customerDevice.accessories,
      damageLiquid: customerDevice.damageLiquid,
      damagePhysical: customerDevice.damagePhysical,
      frontImage: customerDevice.images.front,
      backImage: customerDevice.images.back,
    };
    console.log(data);
    Axios.post("/api/Devices/SetDevice", data)
      .then((res) => {
        SetNotice({
          status: true,
          message:
            "Device :" +
            data.serialPrimary +
            " captured with ID " +
            res.data.id,
          error: false,
        });
      })
      .catch(
        function (error) {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log("Status" + error.response.status);
            console.log(error.response.headers);
            SetNotice({
              status: true,
              message: error.response.data,
              error: true,
            });
            if (error.response.status === 401) {
              dispatch({ type: "LOGOUT", action: "" });
            }
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            SetNotice({ status: true, message: error.request, error: true });
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
            SetNotice({ status: true, message: error.message, error: true });
          }
        }
        //console.log(data);
      );
  }, [customerDevice, subCategory, dispatch]);
  return <></>;
}
