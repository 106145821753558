import React, { useState, useMemo, useCallback, useRef } from "react";
import "./index.scss";
import Header from "../Header";
import Footer from "../Footer";
import ReportForm from "./ReportForm";
import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

export default function Index() {
  const [report, setReport] = useState([]);
  const gridRef = useRef();
  const columnDefs = useMemo(
    () => [
      { field: "model" },
      { field: "imei" },
      { field: "faultCodes" },
      { field: "returnDate" },
      { field: "popDate" },
      { field: "returnType" },
      { field: "approvalJustification" },
      { field: "originalIMEI" },
      { field: "comments" },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
    }),
    []
  );
  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  return (
    <div className="container-fluid vh-100  m-0 p-0">
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="container report-wrapper">
            <div className="params">
              <ReportForm setReport={setReport} />
            </div>
            <div className={report.length !== 0 ? "report" : "report-hidden"}>
              <div className="report-container">
                <div className="row p-2">
                  <div className="btn-wrap">
                    <button className="btn btn-primary " onClick={onBtnExport}>
                      Download CSV export file
                    </button>
                  </div>
                </div>
                <AgGridReact
                  ref={gridRef}
                  className="ag-theme-alpine"
                  defaultColDef={defaultColDef}
                  columnDefs={columnDefs}
                  rowData={report}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
