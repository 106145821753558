export const AuthenticationReducer = (state, action) => {
  let stateUpdate = {};
  switch (action.type) {
    case 'LOGIN':
      //console.log('auth update:', action.data.person);
      localStorage.setItem('person', JSON.stringify(action.data.person));
      localStorage.setItem('token', JSON.stringify(action.data.token));
      stateUpdate = { person: action.data.person, token: action.data.token };
      return state;

    case 'LOGOUT':
      stateUpdate = {
        token: '',
        person: {
          id: '',
          displayName: '',
          firstName: '',
          surname: '',
          department: '',
          contactNumber: '',
          emailAddress: '',
          username: '',
          isAdmin: '',
          memberOf: null,
          distinguishedName: '',
          isAuthenticated: false,
        },
      };
      localStorage.clear();
      return stateUpdate;

    default:
      return state;
  }
};
