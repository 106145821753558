import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ReportType({ reason, device }) {
  return (
    <div className="row align-items-strech report-border">
      <div className="col-1 report-content text-center border-right ">
        {reason.id}.
      </div>
      <div className="col-4 report-content border-right ">
        {reason.category}
      </div>
      <div className="col-7 report-content">
        {reason.subCategories.map((sub, index) => (
          <div className="row " key={index}>
            <div className="col-11">{sub.description}</div>
            <div className="col-1 text-center">
              {device.id_NokiaDoSubRejections === sub.id ? (
                <FontAwesomeIcon
                  icon="check"
                  size="lg"
                  className="icon-color"
                />
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
