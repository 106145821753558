import React, { useState, useCallback } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Webcam from "react-webcam";

export default function Home() {
  const WebcamCapture = () => {
    const [deviceId, setDeviceId] = React.useState({});
    const [devices, setDevices] = React.useState([]);

    const handleDevices = React.useCallback(
      (mediaDevices) =>
        setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
      [setDevices]
    );

    React.useEffect(() => {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }, [handleDevices]);

    return (
      <>
        {devices.map((device, key) => (
          <div key={key}>
            <Webcam
              audio={false}
              videoConstraints={{ deviceId: device.deviceId }}
            />
            {device.label || `Device ${key + 1}`}
            {device.label + "  "}
            {device.deviceId}
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="container-fluid vh-100  m-0 p-0">
      <Header />
      <div className="container-fluid">
        <div>hello</div>
        <div>{WebcamCapture()}</div>
      </div>
      <Footer />
    </div>
  );
}
