import React, { useEffect, useReducer, createContext } from 'react';
import { AuthenticationReducer } from '../reducers/AuthenticationReducer';

export const AuthenticationContext = createContext();

export default function AuthenticationContextProvider(props) {
  const initialState = {
    token: '',
    person: {
      id: '',
      displayName: '',
      firstName: '',
      surname: '',
      department: '',
      contactNumber: '',
      emailAddress: '',
      username: '',
      isAdmin: '',
      memberOf: null,
      distinguishedName: '',
      isAuthenticated: false,
    },
  };
  const [person, dispatch] = useReducer(AuthenticationReducer, [], () => {
    const localData = localStorage.getItem('person');
    return localData != null ? JSON.parse(localData) : initialState;
  });

  useEffect(() => {
    if (localStorage.getItem(person.isAuthenticated) !== null) {
      localStorage.setItem(person, JSON.stringify(person));
    }
  }, [person]);

  return (
    <div>
      <AuthenticationContext.Provider value={{ person, dispatch }}>
        {props.children}
      </AuthenticationContext.Provider>
    </div>
  );
}
