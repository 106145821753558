import React, { useState } from "react";
import Webcam from "react-webcam";
import "./index.scss";

export default function Index({ name, close, action }) {
  const width = 414;
  const height = width / (16 / 9);
  const videoConstraints = {
    width: 1920,
    height: 1090,
    facingMode: "user",
  };
  const [picture, setPicture] = useState("");

  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
  });

  const accept = (name) => {
    action(name, picture);
    close();
  };

  return (
    <div>
      <div>
        {picture === "" ? (
          <Webcam
            audio={false}
            height={height}
            ref={webcamRef}
            width={width}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
          />
        ) : (
          <img src={picture} alt="no capture" />
        )}
      </div>
      <div className="capture-buttons-wrapper">
        <div>
          {picture !== "" ? (
            <button
              onClick={(e) => {
                setPicture("");
              }}
              className="btn-primary"
            >
              Retake
            </button>
          ) : (
            <button
              onClick={(e) => {
                e.preventDefault();
                capture();
              }}
              className="btn-primary"
            >
              Capture
            </button>
          )}
        </div>
        <div>
          {picture !== "" ? (
            <button
              type="button"
              className="btn-primary"
              value={name}
              onClick={(e) => {
                accept(e.target.value);
              }}
            >
              Accept{" "}
            </button>
          ) : (
            <button
              type="button"
              className="btn-primary"
              value={name}
              onClick={close}
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
