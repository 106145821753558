import React from "react";
import "./index.scss";

export default function Index() {
  const results = [
    {
      iemi: "513450085474852",
      status: "Approved",
      date: "2022-10-23",
      returnType: "DOA",
    },
    {
      iemi: "351345008107421",
      status: "Rejected",
      date: "2022-10-13",
      returnType: null,
    },
    {
      iemi: "351201424367802",
      status: "Approved",
      date: "2022-10-03",
      returnType: "DOA",
    },
    {
      iemi: "351345008107421",
      status: "Rejected",
      date: "2022-10-13",
      returnType: null,
    },
    {
      iemi: "351201424367802",
      status: "Approved",
      date: "2022-10-03",
      returnType: "DOA",
    },
  ];
  return (
    <div className="mQueueWrapper">
      <div className="row "></div>
      <div className="heading">Message Queue</div>
      <div className="resultsWrapper">
        {results.map((result, index) => (
          <div className="result" key={index}>
            {result.iemi}-
            {result.returnType === null || result.returnType === undefined
              ? "Non Return"
              : result.returnType}
            -{result.date}-{result.status}
          </div>
        ))}
      </div>
    </div>
  );
}
