import React, { useState, callback } from "react";
import GetDevice from "./GetDevice";
import GetWarranty from "./GetWarranty";
import ProcessDevice from "./ProcessDevice";
import Rejection from "../RejectReport/Index";

export default function DeviceForm({ setNotice, person }) {
  const intial = { set: false, id: -1, imei: null, process: false };
  const [device, setDevice] = useState(intial);

  const updateDevice = (newDevice) => {
    setDevice(newDevice);
  };
  const resetDevice = () => {
    setDevice(intial);
  };
  const process = () => {
    if (device.set === false) {
      return <GetDevice updateDevice={updateDevice} setNotice={setNotice} />;
    } else {
      if (
        device.id > 0 &&
        device.operatorID !== "NotLoaded" &&
        device.process !== true
      ) {
        return (
          <>
            <div className="row">
              <div className="">
                <div className="form">
                  IMEI {device.imei} has already been processed would you like
                  to reprocess?
                  <div className="row p-2">
                    <button
                      className="btn btn-primary col-5"
                      onClick={() => {
                        setDevice((prev) => ({ ...prev, process: true }));
                      }}
                    >
                      Yes
                    </button>
                    <div className="col-2"></div>
                    <button
                      className="btn btn-primary col-5"
                      onClick={() => {
                        setDevice(intial);
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <ProcessDevice
            props={device}
            setNotice={setNotice}
            resetDevice={resetDevice}
          />
        );
      }
    }
  };
  return <>{process()}</>;
}
