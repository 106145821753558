import React, { useState, useContext, useEffect } from "react";
import Header from "../Header";
import CaptureDevice from "./captureDevice";
import Footer from "../Footer";
import { AuthenticationContext } from "../../contexts/AuthenticationContext";
import DeviceForm from "./DeviceForm";

export default function Index() {
  const [notice, setNotice] = useState({
    status: null,
    message: "",
  });
  const { person, dispatch } = useContext(AuthenticationContext);

  return (
    <div className="container">
      <Header />
      <div className="content-wrapper">
        <div
          className={
            notice.status !== null
              ? notice.status === true
                ? "row notice-wrapper"
                : "row notice-wrapper-error"
              : "notice-wrapper-none"
          }
        >
          {notice.status !== null ? (
            <div className="col6 notice-message">{notice.message}</div>
          ) : (
            ""
          )}
        </div>
        <div className="content">
          <DeviceForm setNotice={setNotice} person={person} />
        </div>
      </div>

      <Footer />
    </div>
  );
}
