import React, { useEffect, useState } from "react";
import Select from "react-select";
import Axios from "axios";

export default function Index({ handleChange, error, selected }) {
  const [options, setOptions] = useState([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    Axios.get("/api/Dictionary/GetSymptoms")
      .then((res) => {
        //console.log(res.data);
        const ops = res.data.map((result) => ({
          value: result.flSymptomCode,
          label: result.flSymptomCode + " - " + result.desc,
        }));
        setOptions(ops);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);

  const updateArray = (e) => {
    let newArray = e.map((row, index) => row.value);
    let para = { name: "faultCodes", value: newArray };
    setValues(newArray);
    handleChange(para);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 1,
    }),
  };
  // console.log("Values ", values);
  return (
    <Select
      defaultValue={values}
      isMulti
      styles={customStyles}
      options={options}
      menuPlacement="top"
      onChange={(e) => updateArray(e)}
      required
      className={error + ""}
    />
  );
}
