import React, { useState, useContext, useEffect } from "react";
import Symptoms from "../faultSymptoms/Index";
import RadioButton from "../radioButton/index";
import DatePicker from "react-datepicker";
import { parseISO } from "date-fns";
import CaptureImage from "../CaptureImages/Index";
import Front from "../../assets/images/nokia_front.jpg";
import Back from "../../assets/images/nokia_back.jpg";
import Modal from "react-modal";
import { AuthenticationContext } from "../../contexts/AuthenticationContext";
import Axios from "axios";
import { BeatLoader } from "react-spinners";
import Rejection from "../RejectReport/Index";
import { rootShouldForwardProp } from "@mui/material/styles/styled";

export default function ProcessDevice({ props, setNotice, resetDevice }) {
  const { person, dispatch } = useContext(AuthenticationContext);
  const [modalAction, setModalAction] = useState("photo");
  const [loading, setLoading] = useState(false);

  const [validated, setValidated] = useState(false);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const intitial = {
    id: props.data.id,
    operatorID: person.id,
    imei: props.data.imei,
    model: props.data.model,
    faultCodes: props.data.faultCodes,
    returnDate: parseISO(props.data.returnDate),
    popDate: parseISO(props.data.popDate),
    partNumbers: props.data.partNumbers,
    originalImei: props.data.originalImei,
    customerStoreName: props.data.customerStoreName,
    customerComments: props.data.customerComments,
    id_NokiaDoWarranty: props.data.id_NokiaDoWarranty,
    serialNumberPrimary: props.data.serialNumberPrimary,
    serialNumberSecondary: props.data.serialNumberSecondary,
    productCode: props.data.productCode,
    productType: props.data.productType,
    salesPackageCode: props.data.salesPackageCode,
    materialDescription: props.data.materialDescription,
    salesModelName: props.data.salesModelName,
    softwareVersion: props.data.softwareVersion,
    warrantyRuleName: props.data.warrantyRuleName,
    activationDate: props.data.activationDate,
    activationCountry: props.data.activationDate,
    terminationReason: props.data.terminationReason,
    soldToCustomer: props.data.soldToCustomer,
    soldToCountry: props.data.soldToCountry,
    blacklistInfo: props.data.blacklistInfo,
    soldToStore: props.data.soldToStore,
    hasPop: props.data.hasPop,
    hasPhysicalDamage: props.data.hasPhysicalDamage,
    hasLiquidDamage: props.data.hasLiquidDamage,
    hasAccessories: props.data.hasAccessories,
    hasCRD: props.data.hasCRD,
    hasLatestSoftware: props.hasLatestSoftware,
    isApproved: props.data.isApproved,
    returnType: props.data.returnType,
    reason: props.data.reason,
    id_NokiaDoSubRejections: props.data.id_NokiaDoSubRejections,
    noFaultFound: props.data.noFaultFound,
    operatorComments: props.data.operatorComments,
    frontImage: props.data.frontImage,
    backImage: props.data.backImage,
    dateProcessed: Date(props.data.dateProcessed),
    error: props.data.error,
  };
  const [device, setDevice] = useState(intitial);
  const handleOnChange = (e) => {
    console.log(e);
    setDevice((prev) => ({ ...prev, [e.name]: e.value }));
  };

  const [validation, setValidation] = useState({
    status: false,
    faultCodes: false,
    customerStoreName: false,
    returnType: false,
  });
  const [error, setError] = useState(true);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [name, setName] = useState();

  const openModal = (e) => {
    setName(e);
    setIsOpen(true);
  };

  const updateImage = (name, value) => {
    setDevice((prev) => ({ ...prev, [name]: value }));
  };
  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const toggleRadio = (propName, propValue) => {
    let value = propValue === "true" ? true : false;
    setDevice({ ...device, [propName]: value });
  };

  const validate = () => {
    //console.log(customerDevice);
    let count = [];
    if (device.customerStoreName === "") {
      setValidation({ ...validation, customerStoreName: true });
      count.push(1);
    }

    if (device.faultCodes.length === 0) {
      setValidation((validation) => ({ ...validation, faultCodes: true }));
      count.push(2);
    }

    if (count.length > 0) {
      setValidation((validation) => ({
        ...validation,
        status: true,
      }));
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) return;
    setLoading(true);
    const faults = device.faultCodes.toString();
    const data = {
      id: device.id,
      imei: device.imei,
      operatorId: person.id,
      customerStoreName: device.customerStoreName,
      customerComments: device.customerComments,
      model: device.model,
      originalImei: device.originalImei,
      faultCodes: faults,
      popDate: device.popDate,
      returnDate: device.returnDate,
      hasPop: device.hasPop,
      hasPhysicalDamage: device.hasPhysicalDamage,
      hasLiquidDamage: device.hasLiquidDamage,
      hasAccessories: device.hasAccessories,
      operatorConfirmed: device.operatorConfirmed,
      operatorComments: device.operatorComments,
      frontImage: device.frontImage,
      backImage: device.backImage,
      partNumbers: device.partNumbers,
      dateProcessed: new Date(),
    };
    if (device.id !== -1) {
      setNotice({
        status: true,
        message: "Updating",
        error: false,
      });
      resetDevice();
      setLoading(false);
    } else {
      Axios.post("/api/Devices/CreateDevice", data)
        .then((res) => {
          console.log(res.data);
          let result = "";
          if (res.data.isApproved === true) {
            result =
              "Device:" +
              res.data.imei +
              " captured with ID: " +
              res.data.id +
              " Status and Reason: Approved - " +
              res.data.reason;
          } else {
            result =
              "Device:" +
              res.data.imei +
              " captured with ID: " +
              res.data.id +
              " Status and Reason: Rejected - " +
              res.data.id_NokiaDoSubRejections;
          }
          setNotice({
            status: true,
            message: result,
            error: false,
          });
          //resetDevice();
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log("Status" + error.response.status);
            console.log(error.response.headers);
            setNotice({
              status: true,
              message: error.response.data,
              error: true,
            });
            if (error.response.status === 401) {
              dispatch({ type: "LOGOUT", action: "" });
            }
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            setNotice({ status: true, message: error.request, error: true });
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
            setNotice({ status: true, message: error.message, error: true });
          }
        });
    }

    console.log("Data Object", data);
  };
  //console.log(device);
  //console.log(device.process);
  const displayFront = () => {
    if (device.frontImage === "noImage")
      return <img src={Front} className="card-img-top" alt="no content" />;
    if (device.frontImage.slice(0, 15) === device.imei)
      return (
        <img
          src={
            "https://nokiadoa-dop.technologiagroup.co.za/images/" +
            device.frontImage
          }
          className="card-img-top"
          alt="Else if"
        />
      );
    else
      return (
        <img
          src={device.frontImage}
          className="card-img-top"
          alt={device.frontImage.slice(0, 15) + "==" + device.imei}
        />
      );
  };

  const displayBlack = () => {
    if (device.backImage === "noImage")
      return <img src={Back} className="card-img-top" alt="no content" />;
    if (device.backImage.slice(0, 15) === device.imei)
      return (
        <img
          src={
            "https://nokiadoa-dop.technologiagroup.co.za/images/" +
            device.backImage
          }
          className="card-img-top"
          alt="Else if"
        />
      );
    else
      return (
        <img
          src={device.backImage}
          className="card-img-top"
          alt={device.backImage.slice(0, 15) + "==" + device.imei}
        />
      );
  };
  console.log("the drive is", __dirname);
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Capture Modal"
        ariaHideApp={false}
      >
        <CaptureImage close={closeModal} name={name} action={updateImage} />
      </Modal>
      <div className={loading === true ? "spinner" : "spinner-hide"}>
        <BeatLoader color="#8cd024" loading={loading} size="40px" />
      </div>
      <form className="form">
        <div className="row">
          <div className="col-12">
            <h3>
              {"Information: " + device.model + " - IMEI " + device.imei}
              {device.operatorID === "NotLoaded"
                ? " - Operated ID: " + person.id
                : " - Device ID:" +
                  device.id +
                  " - Operated ID: " +
                  device.operatorID}
            </h3>
          </div>
        </div>

        <div className="row mb-1 p-2">
          <div className="col form-element">
            <label htmlFor="" className="form-label">
              Serial Primary
            </label>
            <input
              type="text"
              className="form-control"
              value={device.serialNumberPrimary}
              readOnly
            />
          </div>
          <div className="col form-element">
            <label htmlFor="" className="form-label">
              Serial Secondary
            </label>
            <input
              type="text"
              className="form-control"
              value={device.serialNumberSecondary}
              readOnly
            />
          </div>
          <div className="col form-element">
            <label htmlFor="" className="form-label">
              Product Code
            </label>
            <input
              type="text"
              className="form-control"
              value={device.productCode}
              readOnly
            />
          </div>
          <div className="col form-element">
            <label htmlFor="" className="form-label">
              Product Type
            </label>
            <input
              type="text"
              className="form-control"
              value={device.productType}
              readOnly
            />
          </div>
        </div>
        <div className="row mb-1 p-2">
          <div className="col form-element">
            <label htmlFor="" className="form-label">
              Model
            </label>
            <input
              type="text"
              className="form-control"
              value={device.salesModelName}
              readOnly
            />
          </div>
          <div className="col-6 form-element">
            <label htmlFor="" className="form-label">
              Description
            </label>
            <input
              type="text"
              className="form-control"
              value={device.materialDescription}
              readOnly
            />
          </div>
          <div className="col form-element">
            <label htmlFor="" className="form-label">
              Software
            </label>
            <input
              type="text"
              className="form-control"
              value={device.softwareVersion}
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <h5>Warrenty Information</h5>
        </div>
        <div className="row mb-1 p-2">
          <div className="col-2 form-element">
            <label htmlFor="" className="form-label">
              Activation Date
            </label>
            <input
              type="text"
              className="form-control"
              value={device.activationDate}
              readOnly
            />
          </div>
          <div className="col-2 form-element">
            <label htmlFor="" className="form-label">
              Activation Country
            </label>
            <input
              type="text"
              className="form-control"
              value={device.activationCountry}
              readOnly
            />
          </div>
          <div className="col-2 form-element">
            <label htmlFor="" className="form-label">
              Store Code
            </label>
            <input
              type="text"
              className="form-control"
              value={device.soldToCustomer}
              readOnly
            />
          </div>
          <div className="col-6 form-element">
            <label htmlFor="" className="form-label">
              Warranty Type
            </label>
            <input
              type="text"
              className="form-control"
              value={device.warrantyRuleName}
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <h5>Customer Details</h5>
        </div>
        <div className="row mb-1 p-2">
          <div className="col-4 form-element">
            <label htmlFor="customerStoreName" className="form-label">
              Customer Name
            </label>
            <input
              type="text"
              className={
                validation.customerStoreName && error
                  ? "form-control error"
                  : "form-control"
              }
              value={device.customerStoreName}
              name="customerStoreName"
              onChange={(e) => handleOnChange(e.target)}
            />
          </div>
          <div className="col-8 form-element">
            <label htmlFor="warrantyRuleName" className="form-label">
              Fault Reason:
            </label>
            <div className="faults">
              <Symptoms
                handleChange={handleOnChange}
                error={validation.faultCodes && error ? "error" : ""}
                selected={device.faultCodes}
              />
            </div>
          </div>

          <div className="row p-2 mb-1">
            <div className="col-12 form-element">
              <label htmlFor="" className="form-label">
                Customer Comments
              </label>
              <textarea
                type="text"
                className="form-control"
                value={device.customerComments}
                name="customerComments"
                onChange={(e) => handleOnChange(e.target)}
              />
            </div>
          </div>
          <div className="row p-2 mb-1">
            <div className="col">
              <div className="form-label">Has POP</div>
              <RadioButton
                toggleRadio={toggleRadio}
                propName="hasPop"
                propValue={device.hasPop}
              />
            </div>
            <div className="col">
              <div className="form-label">Has CRD</div>
              <RadioButton
                toggleRadio={toggleRadio}
                propName="hasCRD"
                propValue={device.hasCRD}
              />
            </div>
            <div className="col">
              <div className="form-label">Has Latest Software</div>
              <RadioButton
                toggleRadio={toggleRadio}
                propName="hasLatestSoftware"
                propValue={device.hasLatestSoftware}
              />
            </div>
            <div className="col">
              <div className="form-label">Has Accessories</div>
              <RadioButton
                toggleRadio={toggleRadio}
                propName="hasAccessories"
                propValue={device.hasAccessories}
              />
            </div>
            <div className="col">
              <div className="form-label">Has liquid damage</div>
              <RadioButton
                toggleRadio={toggleRadio}
                propName="hasLiquidDamage"
                propValue={device.hasLiquidDamage}
              />
            </div>
            <div className="col">
              <div className="form-label">Has Physical Damage</div>
              <RadioButton
                toggleRadio={toggleRadio}
                propName="hasPhysicalDamage"
                propValue={device.hasPhysicalDamage}
              />
            </div>
            <div className="col">
              <div className="form-label">Pop Date</div>
              <DatePicker
                selected={device.popDate}
                className="form-control"
                name="popDate"
                onChange={(date: Date) =>
                  setDevice((prevDevice) => ({
                    ...prevDevice,
                    popDate: date,
                  }))
                }
                dateFormat="yyyy-MM-dd"
                required
              />
            </div>
            <div className="col">
              <div className="form-label">Return Date</div>
              <DatePicker
                selected={device.returnDate}
                onChange={(date: Date) =>
                  setDevice((prevDevice) => ({
                    ...prevDevice,
                    returnDate: date,
                  }))
                }
                dateFormat="yyyy-MM-dd"
                className="form-control"
                name="popDate"
                required
              />
            </div>
          </div>
        </div>

        <div className="row">
          <h5>Photographs</h5>
        </div>
        <div className="row mb-1 p-1">
          <div className="col 6">
            <div className="card">
              {displayFront()}
              <div className="card-body text-center">
                <h5 className="card-title ">Front Image</h5>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => openModal(e.target.value)}
                  value="frontImage"
                >
                  {device.frontImage !== "noImage" ? "Recapture" : "Capture"}
                </button>
              </div>
            </div>
          </div>
          <div className="col 6">
            <div className="card">
              {displayBlack()}
              <div className="card-body text-center">
                <h5 className="card-title ">Back Image</h5>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => openModal(e.target.value)}
                  value="backImage"
                >
                  {device.backImage !== "noImage" ? "Recapture" : "Capture"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-1">
          <h5>Technician Assesment</h5>
        </div>
        <div className="row p-1">
          <div className="col form-element">
            <label htmlFor="" className="form-label">
              Parts Numbers
            </label>
            <input
              type="text"
              value={device.partNumbers}
              name="device.partNumbers"
              onChange={(e) => handleOnChange(e.target)}
              className="form-control"
            />
          </div>
          <div className="col">
            <div className="form-label">No Fault Found</div>
            <RadioButton
              toggleRadio={toggleRadio}
              propName="noFaultFound"
              propValue={device.noFaultFound}
            />
          </div>
          <div className="row ">
            <div className="col-6 form-element">
              <label htmlFor="" className="form-label">
                Comments
              </label>
              <textarea
                type="text"
                className="form-control"
                value={device.operatorComments}
                name="operatorComments"
                onChange={(e) => handleOnChange(e.target)}
                rows={5}
              />
            </div>
            <div className="col">
              <div
                className={
                  device.noFaultFound === true ? "file-space" : "row-hidden"
                }
              >
                <label
                  className="form-label file-label"
                  htmlFor="inputGroupFile02"
                >
                  FQC Report
                </label>
                <div className="input-group ">
                  <input
                    type="file"
                    className="form-control"
                    id="inputGroupFile02"
                  />
                </div>
              </div>

              <div
                className={
                  device.frontImage !== "noImage" &&
                  device.backImage !== "noImage"
                    ? "row p-2 mt-5"
                    : "row-hidden"
                }
              >
                <button
                  type="Submit"
                  className="btn-primary"
                  onClick={(e) => handleSubmit(e)}
                >
                  Process
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
