import React, { useState, useContext } from "react";
import DatePicker from "react-datepicker";
import Axios from "axios";
import { BeatLoader } from "react-spinners";
import { AuthenticationContext } from "../../contexts/AuthenticationContext";

export default function ReportForm({ setReport }) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [status, setStatus] = useState(-1);
  const [loading, setLoading] = useState(false);
  const toggleRadio = (e) => {
    setStatus(parseInt(e));
  };
  const { dispatch } = useContext(AuthenticationContext);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      dateFrom: startDate,
      dateTo: endDate,
      status: status,
    };
    Axios.post("/api/Reports/GetDeviceReport", data)
      .then((res) => {
        setLoading(false);
        setReport(res.data);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setReport([{ model: "No Data" }]);
        }
        if (err.response.status === 401) {
          dispatch({ type: "LOGOUT", action: "" });
        }
        console.log("API Call error : ", err);
      });
  };

  return (
    <div>
      <div className={loading === true ? "spinner" : "spinner-hide"}>
        <BeatLoader color="#8cd024" loading={loading} size="40px" />
      </div>
      <form>
        <div className="mb-3">
          <label htmlFor="popDate" className="form-label">
            Start Date:
          </label>
          <DatePicker
            dateFormat="dd-MM-yyyy"
            selected={startDate}
            onChange={(date: Date) => setStartDate(date)}
            className="form-control"
            name="startDate"
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="popDate" className="form-label">
            End Date:
          </label>
          <DatePicker
            dateFormat="dd-MM-yyyy"
            selected={endDate}
            onChange={(date: Date) => setEndDate(date)}
            className="form-control"
            name="endDate"
            required
          />
        </div>
        <div className="row p-2 mb-1">
          <div className="col">
            <div className="form-label">Record Type</div>
            <div className="radio-group">
              <div className="radio-element">
                <label className="form-check-label " htmlFor="accessories">
                  All
                </label>{" "}
                <input
                  name="status"
                  className="form-check-input"
                  type="radio"
                  value="-1"
                  checked={status === -1 ? "checked" : ""}
                  onChange={(e) => toggleRadio(e.target.value)}
                />
              </div>
              <div className="radio-element">
                <label className="form-check-label " htmlFor="accessories">
                  Approved
                </label>{" "}
                <input
                  name="status"
                  className="form-check-input"
                  type="radio"
                  value="1"
                  checked={status === 1 ? "checked" : ""}
                  onChange={(e) => toggleRadio(e.target.value)}
                />
              </div>
              <div className="radio-element">
                <label className="form-check-label " htmlFor="accessories">
                  Rejected
                </label>{" "}
                <input
                  name="status"
                  className="form-check-input"
                  type="radio"
                  value="0"
                  checked={status === 0 ? "checked" : ""}
                  onChange={(e) => toggleRadio(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row g-1 mt-1">
          <button
            className="btn btn-primary"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Generate
          </button>
        </div>
      </form>
    </div>
  );
}
