import React from "react";

export default function index({ toggleRadio, propName, propValue }) {
  return (
    <div className="radio-group">
      <div className="radio-element">
        <label className="form-check-label " htmlFor="accessories">
          Yes
        </label>{" "}
        <input
          name={propName}
          className="form-check-input"
          type="radio"
          value="true"
          checked={propValue}
          onChange={(e) => toggleRadio(e.target.name, e.target.value)}
        />
      </div>
      <div className="radio-element">
        <label className="form-check-label pull-right" htmlFor="accessories">
          No
        </label>{" "}
        <input
          name={propName}
          className="form-check-input"
          value="false"
          checked={!propValue}
          onChange={(e) => toggleRadio(e.target.name, e.target.value)}
          type="radio"
        />
      </div>
    </div>
  );
}
