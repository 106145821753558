import React, { useEffect, createContext, useState } from "react";

import Axios from "axios";

export const RejectionReasonsContext = createContext();

export default function RejectionReasonsContextProvider(props) {
  const [error, setError] = useState({ isError: false, message: "" });
  const initialState = [
    {
      id: 0,
      category: "",
      subCategories: [
        {
          id: 0,
          description: "",
        },
      ],
    },
  ];
  const [rejectionReason, setRejectionReason] = useState(
    [],
    () => initialState
  );
  useEffect(() => {
    Axios.get("/api/Dictionary/GetRejections")
      .then((res) => {
        setRejectionReason(res.data);
      })
      .catch((err) => {
        console.log("API Call error : ", err);

        if (err.response.status === 401) {
          setError({
            isError: true,
            message: "Unauthorised.Please check username/password",
          });
        }
      });
  }, []);

  return (
    <div>
      <RejectionReasonsContext.Provider value={{ rejectionReason, error }}>
        {props.children}
      </RejectionReasonsContext.Provider>
    </div>
  );
}
