import './scss/App.scss';
import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './components/login/Login';
import Axios from 'axios';
import { AuthenticationContext } from './contexts/AuthenticationContext';
import Report from './components/Reports/Index';
import Nokia from './components/nokia/Index';
import Nokia2 from './components/Client2/Index';
import ProcessClaim from './components/nokia/ProcessClaim';
import RejectReport from './components/RejectReport/Index'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from "@fortawesome/free-solid-svg-icons";
library.add(faCheck)

export default function App() {

  const uri = process.env.REACT_APP_API_HOST.replace(/['"]+/g, '')
  //console.log(uri);
  Axios.defaults.baseURL = uri
  let token = null;
  if(localStorage.getItem('token')!= null){
    token  = localStorage.getItem('token').replace(/['"]+/g, '')
  }
  
  Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  //console.log('Bearer ' +localStorage.getItem('token'))

  const authContext = useContext(AuthenticationContext);
  
  if (!authContext.person.isAuthenticated) {
    return <Login />
  } else {
    return (
      <div className='root'>
        <Routes>
          <Route path='/' element={<Nokia2/>} exact  />
          <Route path='/nokia' element={<Nokia2/>} exact  />
          <Route path='/process' element={<ProcessClaim/>} exact  />
          <Route path='/report' element={<Report/>} exact  />
          <Route path='/reject' element={<RejectReport />} exact  />
        </Routes>
      </div>
    );
  }
}
